<template>
  <div class="orders">
    <div class="order-container">
      <div class="order-department">
        <p style="font-size: 12px; color: #999;text-align: center;margin-bottom: 8px;">
          <span class="remark-circle"></span> 表示仅有微信官方名称
        </p>
        <tree-depart @node-click="handleNodeClick" ref="group"></tree-depart>
      </div>
      <div class="order-list">
        <p>{{departmentName}}</p>
        <el-row class="order-list-filter">
          <el-form label-width="auto" :inline="true" size="mini">
            <el-col :span="8">
              <el-form-item label="支付方式:">
                <el-select placeholder="请选择支付方式" v-model="pagination.payStyle" class="order-input-filter"
                           @change="loadOrder">
                  <el-option v-for="item in orderPayOption"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制作类型:">
                <el-select placeholder="请选择制作类型" class="order-input-filter" v-model="pagination.orderType"
                           @change="loadOrder">
                  <el-option v-for="item in orderTypeOption"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">

                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单编号:">
                <el-input placeholder="请输入订单编号" class="order-input-filter" clearable
                          v-model="pagination.orderUuid" @change="loadOrder"
                          prefix-icon="el-icon-search"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制作时间:">
                <el-date-picker
                  style="max-width: 240px"
                  @change="loadOrder"
                  v-model="makeTimeRange"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>

              </el-form-item>
            </el-col>

            <el-col :span="16">
              <el-button type="primary" size="mini" @click="handSyncOrder">
                <i class="el-icon-refresh"></i> 同步制作订单
              </el-button>

              <el-button type="primary" size="mini" @click="handExportOrder">
                <i class="el-icon-export"></i> 导出订单
              </el-button>
              <el-radio v-model="pagination.onlyDept" style="margin-left: 12px" @change="loadOrder" label="1">部门订单</el-radio>
              <el-radio v-model="pagination.onlyDept" style="margin-left: 12px" @change="loadOrder" label="2">设备订单</el-radio>

              <el-checkbox v-model="wantChild" @change="loadOrder">下级部门</el-checkbox>
            </el-col>
          </el-form>
        </el-row>

        <div class="order-list-data">
          <el-table :data="orders"
                    :row-style="{background: '#fff'}"
                    :cell-style="{background: '#fff'}"
          >
            <empty slot="empty"></empty>

            <el-table-column label="用户" align="center" min-width="80" fixed>
              <template slot-scope="scope">
                    <span v-if="scope.row.userUuid.length !=0">
                      {{scope.row.userUuid}}
                    </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column label="部门名称" align="center" prop="departmentName" min-width="160" fixed></el-table-column>
            <el-table-column label="饮品名称/杯量" align="center" prop="name" min-width="160" fixed>
              <template slot-scope="scope">
                <div class="user-info">
                    <span>
                      {{scope.row.name}}/{{scope.row.size |filterSize}}
                    </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="设备名称" align="center" prop="deviceName" min-width="120"></el-table-column>
            <el-table-column label="支付金额" align="center" width="70">
              <template slot-scope="scope">
                <span>{{(parseInt(scope.row.money) / 100).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="原始金额" align="center" width="70">
              <template slot-scope="scope">
                <span>{{(parseInt(scope.row.originPrice) / 100).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单来源/订单类型" align="center" min-width="130">
              <template slot-scope="scope">
                <div class="user-info">
                            <span :class="[{ notPay: scope.row.payStyle ==1 }, { aliPay: scope.row.payStyle ==2 },
                                    { wechatPay: scope.row.payStyle ==3 }]">
                                  {{scope.row.payStyle | filterPay}}
                                </span>
                  /<span :class="[{ testPayMake: scope.row.orderType ==1 }, { notPayMake: scope.row.orderType ==2 },
                                    { payMake: scope.row.orderType ==3 }]">
                                   {{scope.row.orderType | filterMakeStyle}}
                                </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" align="center">
              <template slot-scope="scope">
                <div class="user-info">
                            <span :class="[{ offline: scope.row.status ==0 }, { fault: scope.row.status ==2 },
                                    { normal: scope.row.status ==1 }]">
                                   <span class="circle"
                                         v-show="scope.row.status ==1"></span> {{scope.row.status | filterOrderStatus}}
                                </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="订单编号" align="center" prop="orderUuid" min-width="240"></el-table-column>

            <el-table-column label="订单时间" align="center" min-width="140">
              <template slot-scope="scope">
                <span>{{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
              <el-pagination
                @current-change="handleCurrentChange"
                class="pagination"
                :current-page="pagination.curPage"
                :page-size="pagination.limit"
                layout="total, prev, pager, next, jumper"
                :total="pagination.total">
              </el-pagination>
      </div>
    </div>


    <loading :show.sync="showDownload"></loading>
  </div>

</template>

<script>
  import {workOrderUnion, workOrderList, workOrderSync, workOrderExport, workOrderExportQuery} from '@/api/work/order'
  import {workDepartmentList} from '@/api/work/department'
  import Loading from "../../../components/Loading/index";
  import DepartmentName from "../../../components/DepartmentName";
  import TreeDepart from '@/components/TreeDepart/index.vue'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {Loading, DepartmentName, TreeDepart},
    activated: function () {
      workDepartmentList({
        code: this.employee.code,
        corpid: this.employee.corpid
      }).then(res => {
        this.departments = [{realName: '全部', id: 0}].concat(res.data || [])
      })
      this.loadOrder()
    },
    computed: {
      ...mapGetters(['employee']),
    },
    data: function () {
      return {
        orders: [],
        showDownload: false,
        wantChild: true,
        departmentName: '',
        pickerOptions: {

          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0, 0, 0, 0)
              end.setHours(23, 59, 59, 0)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              start.setHours(0, 0, 0, 0)
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              end.setHours(23, 59, 59, 0)

              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        orderPayOption: [
          {label: '所有支付', value: 0},
          {label: '无支付', value: 1},
          {label: '支付宝', value: 2},
          {label: '微信支付', value: 3},
          {label: '用户免费', value: 4},
          {label: '部门免费', value: 5},
        ],
        orderTypeOption: [
          {label: '所有订单', value: 0},
          {label: '测试订单', value: 1},
          {label: '无支付订单', value: 2},
          {label: '支付订单', value: 3},
        ],
        pagination: {
          startAt: 0,
          endAt: 0,
          orderType: 0,
          payStyle: 0,
          total: 0,
          departmentUuid: 0,
          orderUuid: '',
          current: 0,
          start: 0,
          limit: 10,
          onlyDept: '2',
        },
        departments: [],
        makeTimeRange: [],
        loading: false,
        devices: []

      }
    },
    filters: {
      filterReturnStatus(returnStatus) {
        switch (returnStatus) {
          case 0:
          case 1:
            return '未退款'
          case 2:
            return '已退款'
        }
      },
      filterOrderStatus(status) {
        switch (status) {
          case 0:
            return '制作中'
          case 2:
            return '制作失败'
          case 1:
            return '成功'
        }
      },
      filterSize(size) {
        switch (size) {

          case 1:
            return '中杯'
          case 2:
            return '大杯'
          default:
            return '小杯'
        }
      },
      filterPay(style) {
        switch (style) {
          case 0:
            return '无支付'
          case 1:
            return '无支付'
          case 2:
            return '支付宝'
          case 3:
            return '微信支付'
          case 4:
            return '用户免费'
          case 5:
            return '部门免费'
        }
      },

      filterMakeStyle(style) {
        switch (style) {
          case 1:
            return '测试'
          case 2:
            return '无支付'
          case 3:
            return '支付'
        }
      }
    },
    methods: {
      handleNodeClick(node) {
        if (this.pagination.wxId === node.id) {
          return
        }

        this.departmentName = node.realName

        this.pagination.departmentUuid = node.id
        this.loadOrder()
      },
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        if (this.makeTimeRange.length > 1) {
          this.pagination.startAt = parseInt(this.makeTimeRange[0] / 1000)
          this.pagination.endAt = parseInt(this.makeTimeRange[1] / 1000)
        } else {
          this.pagination.startAt = 0
          this.pagination.endAt = 0
        }
        if (this.wantChild) {
          this.pagination.child = 1
        } else {
          this.pagination.child = 0
        }

        workOrderList({
          ...this.pagination,
          code: this.employee.code,
          corpid: this.employee.corpid
        }).then(res => {
          this.orders = res.data || []
        })
      },

      loadOrder() {
        let vue = this
        this.pagination.curPage = 1
        this.pagination.start = 0
        if (this.makeTimeRange.length > 1) {
          this.pagination.startAt = parseInt(this.makeTimeRange[0] / 1000)
          this.pagination.endAt = parseInt(this.makeTimeRange[1] / 1000)
        } else {
          this.pagination.startAt = 0
          this.pagination.endAt = 0
        }
        if (this.wantChild) {
          this.pagination.child = 1
        } else {
          this.pagination.child = 0
        }
        workOrderUnion({
          ...this.pagination,
          code: this.employee.code,
          corpid: this.employee.corpid
        }).then(res => {
          vue.orders = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },
      handExportOrder() {
        let vue = this
        this.pagination.curPage = 1
        this.pagination.start = 0
        if (this.makeTimeRange.length > 1) {
          this.pagination.startAt = parseInt(this.makeTimeRange[0] / 1000)
          this.pagination.endAt = parseInt(this.makeTimeRange[1] / 1000)
        } else {
          this.pagination.startAt = 0
          this.pagination.endAt = 0
        }

        if (this.orders.length === 0) {
          this.$message.warning("暂无可导出订单")
          return
        }

        this.showDownload = true
        workOrderExport({
          ...this.pagination,
          code: this.employee.code,
          corpid: this.employee.corpid
        }).then(res => {
          this.getOrder(res.data)
        })
      },
      getOrder(uuid) {
        let vue = this
        setTimeout(function () {
          let ts = setInterval(function () {
            workOrderExportQuery({uuid}).then(res => {
              if (res.data.status === 1) {
                let downloadElement = document.createElement('a');
                downloadElement.href = res.data.url;
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                clearInterval(ts)
                vue.showDownload = false
              } else {
                if (res.data.reason !== '') {
                  vue.$message.error(res.data.reason)
                  clearInterval(ts)
                  vue.showDownload = false
                  return
                }
              }
            })
          }, 1500)
        }, 500)
      },
      handSyncOrder() {
        if (this.makeTimeRange.length <= 1) {
          this.$message.warning('请选取同步时间')
          return
        }
        workOrderSync({
          startAt: parseInt(this.makeTimeRange[0] / 1000),
          endAt: parseInt(this.makeTimeRange[1] / 1000)
        }).then(res => {
          this.$message.success('同步成功')
          this.loadOrder()
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .orders {

    width: 100%;
    height: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .order-container{
      display: flex;
      width: 100%;
      height: 100%;
      .order-department {
        border-right: 1px solid $--border-color-light;
        padding-right: 12px;
        width: 256px;
      }


      .order-list {
        margin-top: $padding;
        padding-left: $padding;
        flex: 1;
        overflow-x: scroll;

        .order-list-data{
          width: 100%;
        }

        body .el-table td.is-center, body .el-table th.is-center {
          background-color: #fff;
        }

        .order {
          padding: 0 .3rem;
        }

        .order-edit, .order-delete {
          cursor: pointer;
          display: inline-block;

          i {
            margin-right: 2px;
          }
        }

        .order-edit:hover, .order-delete:hover {
          //color: $f_color;
        }

        .order-edit {
          color: $btn_color;
        }

        .order-edit:hover {
        }

        .order-delete {
          color: $btn_sec_color;
        }

        .order-delete:hover {
        }

        .disable {
          cursor: not-allowed;
          color: $--font-03-color;
          border: none;
        }

        .disable:hover {
          //    color: $f_50_color;
          //   background-color: $bg_six_color;
        }

        .circle {
          // border-radius: $small_font_size;
          // width: $small_font_size;
          // height: $small_font_size;
          display: inline-block;
          margin-right: 5px;
        }

        .wechatPay {
          color: #44b549;
        }

        .aliPay {
          color: #00aaee;
        }

        .testPayMake {
          color: #5AD8A6;
        }

        .notPayMake {
          color: #F6BD16;
        }

        .payMake {
          color: #5B8FF9;
        }

        .offline {
          color: #5B8FF9;

          .circle {
            background-color: #5B8FF9;
          }
        }

        .normal {
          color: #5AD8A6;

          .circle {
            background-color: #5AD8A6;
          }
        }

        .warning {
          color: #F6BD16;

          .circle {
            background-color: #F6BD16;
          }
        }

        .fault {
          color: #E8684A;

          .circle {
            background-color: #E8684A;
          }
        }

      }

      .pagination {
        margin: $padding;
        text-align: right;
      }
    }


  }

</style>
