/**
 * Created by fworld on 2019/9/25.
 */
import request from '@/util/request'


export const workOrderUnion = (params) => {
  return request({
    'url': '/api/v1/work/order/union',
    'method': 'GET',
    params
  })
}


export const workOrderList = (params) => {
  return request({
    'url': '/api/v1/work/order/list',
    'method': 'GET',
    params
  })
}

export const workOrderSync = (params) => {
  return request({
    'url': '/api/v1/work/order/sync',
    'method': 'GET',
    params
  })
}

export const workOrderExport = (params) => {
  return request({
    'url': '/api/v1/work/order/export/down',
    'method': 'GET',
    params:{
      ...params,
      hideLoading: true,
    }
  })
}

export const workOrderExportQuery = (params) => {
  return request({
    'url': '/api/v1/work/order/export/query',
    'method': 'GET',
    params:{
      ...params,
      hideLoading: true,
    }
  })
}
